
import React from 'react';
//import bg from '../assets/images/pexels-ksenia-chernaya-3965543HD.jpg';
import icl from '../assets/images/ICL.jpg';
import lmi from '../assets/images/LMI.png';

import Article from '../components/Article';
import SubPageStyles from '../styles/SubPageStyles';

export default function CoachingPage() {
  return (
    <SubPageStyles >
        <Article title="Coaching">
        
        <p>Certificado por el INILID(Instiruto Internacional de Liderazgo) de Colombia y por el ICL(siglas en inglés para International Coaching Leadership) de Estados Unidos. Brindo los servicios de coaching en su organización con el fin de permitirles a sus colaboradores que liberen el potencial personal y profesional que llevan en su interior.</p>
           
           <img src={icl}>
           </img>
           <img src={lmi}>
           </img>

            <p>Cuántas veces usted se ha preguntado: <b>“¿por qué las metas organizacionales o proyectos particulares no se me dan como estaba planeado?”</b> Y quizá, usted se pregunte también por qué algún colaborador suyo en especial, tiene rendimientos fluctuantes y el desempeño no está acorde a las expectativas.</p>
            <p>Con una técnica de <b>coaching</b> basada en 10 principios claves de desempeño para lograr el balance personal, social y laboral del individuo; lo acompaño en su gestión: para que los líderes de su organización puedan ejercer sus funciones de liderazgo, encontrándose primero ellos mismos y pudiendo así, <b>direccionar</b> después a los colaboradores que les reportan.</p>
        
            <p>Recuerde que el líder está en medio de sus colaboradores. Él no puede abstraerse de su grupo de acción porque estará posicionándose en un nivel de mandar o dirigir desde el afuera del escenario de los acontecimientos, y por ende, su autoridad se verá afectada; perdiendo la oportunidad de ser un modelo que lidere con el ejemplo.</p>
            <p>Todos los servicios que a continuación se presentan, pueden ir acompañados, si usted lo desea, con <b>prácticas de coaching</b> en las cuales sus colaboradores liberarán su potencial para poner en práctica todo su profesionalismo y comportamiento humano en pro de la organización. Otra forma de prestar las prácticas de coaching, es de forma independiente, como una formación de sus colaboradores (un valor agregado para la persona).</p>

            <br />
           
            <h3>"Coaching de vida, coaching ejecutivo, coaching organizacional. Dejando atrás los miedos y barreras en el desempeño personal y organizacional para afrontar un futuro con éxito".</h3>
            <br />
            <br />
        </Article>
    </SubPageStyles>
  );
}
